@media (min-width: 992px) {
  .nav-items {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .mobile-view-logo {
    display: none;
  }
}
@media (max-width: 767px) {
  .logo {
    display: none;
  }

  .desktop-view-logo {
    display: none;
  }
}
