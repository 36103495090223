.sitefooter {
  position: relative;
  text-align: center;
  color: rgb(0, 0, 0);
}
.footerimage {
  height: 600px;
  width: 100%;
}
.sitefooter-body {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
}

.footerchange {
  margin-left: 60px;
}
.footerheader {
  font-size: 35px;
  margin-bottom: 20px;
}

.para {
  font-size: 25px;
  height: auto;
  text-align: justify;
  margin-bottom: 30px;
}

.social-media-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.iconfacebook,
.iconinstagram,
.iconlinkedin {
  cursor: pointer;
  color: white;
}

.heading {
  font-size: 2.5rem !important;
  color: #ffffff;
  text-shadow: 2px 2px #ff0000;
  margin-bottom: 70px;
  text-align: center;
}

@media (max-width: 767px) {
  .sitefooter {
    position: relative;
    text-align: center;
    color: rgb(0, 0, 0);
  }
  .footerimage {
    height: 375px;
    width: 100%;
  }
  .sitefooter-body {
    width: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: black;
    text-align: left;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 10px;
  }
  .footerchange {
    margin-left: 36px;
  }
}
