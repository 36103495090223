.ads-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 728px;
  height: 90px;
  z-index: 1000;
  margin-left: 220px;
}

.ads-container.show {
  display: flex;
}

.adsbygoogle ins {
  text-decoration: none;
  color: inherit;
}
