.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.highlight {
  font-family: "Lucida Handwriting";
  font-size: 20px;
  color: brown;
}

.logo-container img {
  width: 120px;
  height: 120px;
}

a {
  text-decoration: none; /* Removes underline from all anchor tags */
}
