.App {
  text-align: center;
}

p {
  font-size: 15px;
}

li {
  font-size: 15px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (min-width: 767px) {
  .contact-bg {
    background: url("./../public/images/background.png");
    width: 90%;
    height: 130vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: flex;
    align-items: center;
  }
}

.contact-input {
  width: 15%;
  height: 45px;
  background: rgb(191 236 241);
  padding: 5px 5px;
  border: 0.7px solid black;
}

.contact-span {
  font-size: 1rem;
  padding: 0px -0.3rem;
}

.comment-box {
  line-height: 30px;
}

.contact-input:active {
  border: 0.7px solid black;
}

@media (max-width: 767px) {
  .contact-bg {
    background: url("./../public/images/background.png");
    width: 100%;
    height: 40vh;
    background-position: initial;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .contact-input {
    width: 50%;
    height: 20px;
    background: rgb(191 236 241);
    padding: 2px;
    border: 0.7px solid black;
  }
}
